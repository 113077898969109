import defaultConfig from '@/configs/defaultConfig'
import axiosIns from '@/libs/axios'
import { of } from 'rxjs'
import moment from 'moment'
import { groupBy, map, mergeMap, reduce, toArray, sort } from 'rxjs/operators'

export default {
  namespaced: true,
  state: {
    archivedCauselists: [],
    archivedCauselistLoading: false
  },
  getters: {},
  mutations: {
    setArchivedCauselist(state, payload) {
      let pl = payload.sort(function (a, b) {
        return new Date(b.datetime) - new Date(a.datetime)
      })
      of(...pl)
        .pipe(
          groupBy((p) => moment(p.datetime).format('DD-MM-YYYY')),
          mergeMap((group$) =>
            group$.pipe(reduce((acc, cur) => [...acc, cur], [`${group$.key}`]))
          ),
          map((arr) => ({ date: arr[0], data: arr.slice(1) })),
          toArray()
        )
        .subscribe((p) => {
          state.archivedCauselists = p
        })
    },
    changeArchivedCauselistLoading(state, val) {
      state.archivedCauselistLoading = val
    }
  },
  actions: {
    // Get Matter
    fetchArchivedCauselist({ commit }, { ctx, perPage, page }) {
      commit('changeArchivedCauselistLoading', true)
      console.log("URI", defaultConfig.getArchivedCauselistEndPoint)
      return new Promise((resolve, reject) => {
        axiosIns
          .get(
            defaultConfig.getArchivedCauselistEndPoint({
              perPage: perPage,
              page: page
            })
          )
          .then((response) => {
            commit('changeArchivedCauselistLoading', false)
            console.log('Archived', response.data)
            return resolve(response.data)
          })
          .catch((error) => {
            commit('changeArchivedCauselistLoading', false)
            reject(error)
          })
      })
    },
    setArchiveList({ commit }, { payload }) {
      commit('setArchivedCauselist', payload)
    }
  }
}
